import React from 'react'

const Footer = () => {
  return (
    <div
      id="contact"
      style={{
        borderTopWidth: '1px',
      }}
      className="w-full mt-8 mb-4 px-2 md:px-0 border-inherit"
    >
      <div className="container mx-auto flex flex-col md:flex-row align-items justify-center md:justify-between">
        <div className="flex  items-center justify-center w-32 ">
          <img src="assets/logo.webp" />
        </div>
        <div className="mt-5">
          <p className="font-bold">Téléphone: +212.661.390.567</p>
          <p className="font-bold">Mail: dounia.chraibi@ubuntubydc.com</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
