import React from 'react'

const Section = () => {
  return (
    <div className="w-full h-auto bg-white  text-black mt-14 px-2 md:px-0">
      <div className="container mx-auto py-10 flex flex-col-reverse md:flex-row items-center justify-between">
        <div className="w-full md:w-1/2 wr-0 md:mr-4 relative h-full self-stretch grid gap-4 grid-cols-2 grid-rows-2   p-2">
          <img src="assets/eng8.jpg" />
          <img src="assets/eng5.png" />

          <img src="assets/eng7.jpg" />
        </div>
        <div className="w-full md:w-1/2 mt-2 md:mt-0">
          <h2 className="font-bold text-2xl">
            Opération de reboisement… à la campagne !
          </h2>
          <p style={{ width: '90%' }} className="mt-8">
            Le 25 décembre 2020, une journée de distribution d’arbres fruitiers
            a eu lieu dans deux régions agricoles aux alentours de Berrechid,
            avec le concours de l’association High Atlas Foundation. 1600
            oliviers, figuiers, grenadiers et quelques caroubiers ont été
            distribués à une quarantaine d’agriculteurs. Des conseils de
            plantation durable ont été prodigués pour assurer la survie des
            plants, ainsi que des conseils en matière d’agroforesterie.
            L’objectif : faire reculer le désertification des sols, et convertir
            progressivement les cultures et les pratiques. Le suivi est fait par
            l’association High Atlas Foundation, et par Dounia Chraïbi pour les
            individus qu’elle connaît.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section
