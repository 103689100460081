import logo from './logo.svg'
import Header from './components/header.component'
import Domaine from './components/domaine-expertise.component'
import Hero from './components/Hero.component'
import Founder from './components/founder.component'
import './App.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Engagements from './components/engagement.component'
import Section from './components/section.component'
import References from './components/references.component'
import Footer from './components/footer.component'

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Founder />
      <Domaine />
      <References />
      <Engagements />
      <Section />

      <Footer />
    </div>
  )
}

export default App
