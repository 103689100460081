import React from 'react'
import Navigation from './navigation.component'
import NavigationMobile from './navigation.mobile.component'

const Header = () => {
  return (
    <div className="w-full bg-white drop-shadow-md fixed top-0 left-0 right-0 z-40">
      <div className="container mx-auto px-2 md:px-0 flex items-center justify-between">
        <div className="flex items-center justify-center w-32 ">
          <img style={{ width: '100%' }} src="assets/logo.webp" />
        </div>
        <Navigation />
        <NavigationMobile />
      </div>
    </div>
  )
}

export default Header
