import React from 'react'
import { MdEngineering } from 'react-icons/md'

const Card = ({ title, children, items, color, center }) => {
  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={`drop-shadow-md pt-4  text-white col-span-1 flex flex-col items-center `}
    >
      {children}
      <h3 className="font-bold  text-center text-lg color-black uppercase mb-4 mt-3 px-2">
        {title}
      </h3>

      <div className="w-full h-full flex flex-col items-center justify-between">
        {items.map((item) => (
          <div
            key={item}
            className="py-4 px-1 flex items-center w-full flex items-center justify-center "
          >
            <h2 className="text-center">{item}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Card
