import React, { useState } from 'react'
import { BiMenuAltRight } from 'react-icons/bi'

const NavigationMobile = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className="md:hidden">
      <BiMenuAltRight
        className="cursor-pointer"
        onClick={() => setOpen(!open)}
        size={24}
      />
      {open && (
        <div className="absolute flex flex-col items-center justify-center bg-white right-0  left-0 p-2">
          <a href="#indentite" className="py-2">
            Identité
          </a>
          <a href="#domaines" className="py-2">
            Domaines d’expertise
          </a>
          <a href="#references" className="py-2">
            Références
          </a>
          <a href="#engagements" className="py-2">
            Engagements
          </a>
          <a href="#contact" className="py-2">
            Contactez nous
          </a>
        </div>
      )}
    </div>
  )
}

export default NavigationMobile
