import React from 'react'
import Cardcarousel from './card-carousel.component'
import Slider from 'react-slick'
import Title from './title.component'

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
}

const Hero = () => {
  return (
    <div
      id="indentite"
      className="hero md:px-0 w-full h-auto  flexitems-center justify-center"
    >
      <div className="pt-32 mb-12">
        <Title title="Identité" />
      </div>
      <div className=" container mx-auto  pb-8 ">
        <Slider className="slide-x" {...settings}>
          <Cardcarousel
            text="Un anthropologue a demandé un jeu aux enfants d’une tribu d’Afrique du
Sud, les Xhosa. Il a mis un panier de fruits près d’un arbre et a dit aux enfants
que le premier arrivé gagnerait tous les fruits. Au signal, tous les enfants se
sont élancés en même temps… en se donnant la main ! Puis ils se sont assis
ensemble pour profiter de leur récompense. Lorsque l’anthropologue leur a
demandé pourquoi ils avaient agi ainsi, ils ont répondu « Ubuntu! Comment
l’un d’entre nous peut-il être heureux si tous les autres sont tristes? »."
            importantText="‘Ubuntu’ signifie dans la culture Xhosa : « JE suis parce que NOUS sommes ».
Nelson Mandela, entre autres, y a souvent fait référence sa vie durant."
            img="assets/main.jpg"
          />
          <Cardcarousel
            text="Notre identité s’ancre dans la sagesse de l’ubuntu, qui nous
rappelle qu’étant tous liés et partageant un destin collectif, nous
gagnons tous ou nous perdons tous, inéluctablement."
            importantText="Les valeurs qui découlent naturellement de l’ubuntu sont celles
qui nous animent et nous font croire qu’à travers le
développement durable, qui place la responsabilité au cœur des
activités humaines, un modèle gagnant-gagnant, créateur de
valeur économique, sociale, sociétale et environnementale, est
rendu possible."
            img="assets/main.jpg"
          />
        </Slider>
      </div>
    </div>
  )
}

export default Hero
