import React from 'react'
import Title from './title.component'
import Card from './service-card.component'
import { BsFillBarChartFill } from 'react-icons/bs'
import { HiDocumentReport } from 'react-icons/hi'
import { MdEngineering } from 'react-icons/md'
import { FaChessRook, FaChessQueen } from 'react-icons/fa'
const Domaine = () => {
  return (
    <div id="domaines" className="w-full pt:14 md:pt-20 px-2 md:px-0">
      <div className="container mx-auto ">
        <Title title="Domaines d'expertise" />
        <p className="text-center mt-10 w-full md:w-3/4	mx-auto ">
          <strong>
            UBUNTU est une structure de conseil spécialisée en Responsabilité
            Sociétale et Environnementale .
          </strong>{' '}
          <br /> Notre ambition est d’accompagner nos partenaires, de toutes
          tailles et de tous horizons, à mettre en place et à enrichir leurs
          démarches de RSE afin de donner du <strong>sens</strong> à leurs
          activités, avoir plus <strong>d’impacts</strong> positifs, et{' '}
          <strong>créer ensemble un futur possible pour tous.</strong>
        </p>
        <div className="w-full mt-10 grid gap-2 md:gap-4 grid-cols-1 md:grid-cols-3 grid-rows-1">
          <Card
            color="#FFC000"
            items={[
              'Diagnostic interne 360°',
              'Parties prenantes -Risques- Matérielité',
              'Charte et politique RSE',
              'Axes stratégiques et leurs déclinaisons',
              "Alignement de l'organisation et de la gouvernance",
              'Indicateurs - Outils de pilotage et de suivi',
              'Déploiement via un PMO - Conduite du changement',
            ]}
            title="Stratégie RSE & Opérationnalisation"
          >
            <FaChessQueen size="40" />
          </Card>
          <Card
            color="#06BE81"
            items={[
              'Etude de concept',
              'Montage de projet',
              'Implication des parties prenantes',
              'Partenariats',
              'Renforcement des capacités',
              'Suivi et évaluation',
              'Outils de diffusion / communication',
            ]}
            title="Ingénierie de projets & Animation de programmes"
          >
            <MdEngineering size="43" />
          </Card>
          <Card
            color="#8238BA"
            items={[
              'Mise en place du reporting RSE',
              'Rédaction du reporting (aux normes GRI)',
              'Chartes et documents internes',
              'Certifications et labellisations',
              'Contenus pour une communication positive et responsable',
              'Politique du sponsoring',
              'Ancrage interne',
              'Formations internes',
            ]}
            title="Reporting extra-financier & Communication RSE"
          >
            <HiDocumentReport size="43" />
          </Card>
        </div>
        <h2 className="text-md font-bold text-center mt-6">
          Téléchargez{' '}
          <a className="underline" href="Plaquette UBUNTU.pdf">
            la plaquette d’UBUNTU
          </a>
        </h2>
      </div>
    </div>
  )
}

export default Domaine
