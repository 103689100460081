import React from 'react'

const Navigation = () => {
  return (
    <ul className="hidden md:flex w-full  items-center justify-end space-x-8">
      <li>
        <a href="#indentite">Identité</a>
      </li>
      <li>
        <a href="#domaines">Domaines d’expertise</a>
      </li>
      <li>
        <a href="#references">Références</a>
      </li>
      <li>
        <a href="#engagements">Engagements</a>
      </li>

      <li>
        <a
          href="#contact"
          className="text-white px-4 py-3 rounded-lg bg-amber-500 font-medium drop-shadow-md cursor-pointer"
        >
          Contactez nous
        </a>
      </li>
    </ul>
  )
}

export default Navigation
