import React from 'react'
import Marquee from 'react-fast-marquee'
import Title from './title.component'

const settings = {
  dots: true,
  infinite: true,
  speed: 900,
  slidesToShow: 3,
  slidesToScroll: 3,
}
const References = () => {
  return (
    <div id="references" className="w-full mt-14">
      <div className="container mx-auto py-8">
        <div className="w-full mb-4">
          <Title title="Missions & Références" />
        </div>

        <Marquee pauseOnHover pauseOnClick speed={50}>
        <div className="h-58 w-58 mx-4  flex items-center justify-center  ">
            <img src="assets/part.png" />
          </div>
          <div className="h-52 w-52  mx-4 flex items-center justify-center  ">
            <img src="assets/r1.jpg" />
          </div>
          <div className="h-52 w-52 mx-4 flex items-center justify-center  ">
            <img src="assets/r2.png" />
          </div>
          <div className="h-52 w-52  mx-4 flex items-center justify-center  ">
            <img src="assets/r3.png" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/r4.png" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/r20.jpg" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/r21.png" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/r5.png" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/r22.jpg" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/aivam.jpg" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/onapar.png" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/marocpme.jpg" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/ministre2.jpg" />
          </div>
          <div className="h-52 w-52 mx-4  flex items-center justify-center  ">
            <img src="assets/maess.png" />
          </div>
          
        </Marquee>
      </div>
    </div>
  )
}

export default References
