import React from 'react'
import Title from './title.component'

const Engagements = () => {
  return (
    <>
      <div className="mt-4">
        <Title title="Engagements" />
      </div>
      <div
        id="engagements"
        className="w-full h-auto text-white mt-16 px-2 md:px-0"
      >
        <div className="container mx-auto py-10 flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 mr-0 md:mr-2">
            <h2 className="font-bold text-2xl text-left text-black">
              InspYre, ou comment participer à l’émergence d’une relève qui rend
              fier!
            </h2>
            <p style={{ width: '90%' }} className="mt-8 text-black">
              InspYre est une initiative citoyenne et une aventure humaine,
              récompensée par le prix du meilleur projet dans l’Education en
              2017 par le New Work Lab, et distinguée par le TIZI Leadership Day
              en 2020. Fondée en 2015, InspYre a pour mission de révéler le
              potential et libérer les énergies des étudiants des grandes écoles
              de commerce publiques marocaines, et les accompagner dans la
              réussite des concours oraux notamment à travers le mentoring. Une
              cinquantaine de bénévoles ont accompagné depluis plusieurs
              centaines d’étudiants à se mettre en marche sur le chemin de leur
              avenir.
            </p>
            {/*
             <p className="font-bold mt-5">
              Pour plus d’informations au sujet d’InspYre : Lien site web . Lien
              linkedin / lien FB / lien Insta
            </p>
    */}
          </div>
          <div className="w-1/2 relative h-full self-stretch grid gap-4 grid-cols-1 md:grid-cols-2 grid-rows-2   p-2">
            <img src="assets/eng2.png" />
            <img src="assets/eng4.jpg" />
            <img src="assets/rng3.jpg" />

            <img src="assets/eng6.jpg" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Engagements
