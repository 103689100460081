import React, { useEffect } from 'react'
import { parallax } from '../utils/index'

const Founder = () => {
  useEffect(() => {
    console.log(document.querySelector('.parallax'))
    parallax()
  })
  return (
    <div className="w-full bg-white px-2 md:px-0 py-12">
      <div className="container mx-auto flex flex-col md:flex-row-reverse  items-center justify-between">
        <div className="w-full md:w-1/2">
          <h1 className="text-2xl mb-6 text-left">
            <strong>Dounia Chraïbi</strong> est la fondatrice d’UBUNTU.
          </h1>
          <p className="text-left mb-4 font-medium text-lg ">
            14 années d’expérience professionnelle dont 9 dans le développement
            durable et la RSE, et entrepreneure par ailleurs depuis 4 années
            dans le secteur agricole.
          </p>
          <p className="text-left">
            Retrouvez ici son profil complet Lien vers{' '}
            <a
              target="_blank"
              href="https://www.linkedin.com/in/dounia-chra%C3%AFbi-3978391b/?locale=en_US"
              className="font-bold text-blue-500"
            >
              linkedin
            </a>
          </p>
        </div>
        <div className="pl-0 md:pl-6 w-full md:w-1/2 flex items-center justify-center  md:justify-start relative parallax">
          <img
            className=" z-40 layer relative"
            style={{ height: '450px' }}
            src="assets/I. Accueil - pic 3.jpg"
          />

          <div className="hidden md:block one z-10 bg-amber-500 absolute -left-1 -bottom-6" />
        </div>
      </div>
    </div>
  )
}

export default Founder
