import React from 'react'

const Title = ({ title }) => {
  return (
    <h2 className="text-3xl text-center text-amber-500 font-bold relative title ">
      {title}
    </h2>
  )
}

export default Title
