import React from 'react'

const Cardcarousel = ({ img, text, importantText }) => {
  return (
    <div className="rounded-xl w-full flex flex-col md:flex-row items-center justify-between ">
      <div className="w-full md:w-1/2 self-stretch p-6 bg-slate-100">
        <p className="w-full text-left text-lg font-normal ">{text}</p>
        <p className="mt-4 font-bold text-left">{importantText}</p>
      </div>
      <div
        style={{
          height: '400px',
          backgroundImage: `url(${img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className=" w-full md:w-1/2  "
      />
    </div>
  )
}

export default Cardcarousel
